<template>
  <v-dialog v-model="dialog" persistent max-width="850">
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-600px scroll-y mx-5 py-7">
        <div class="d-flex flex-column flex-md-row align-center">
          <div class="w-50">
            <v-radio-group
              row
              mandatory
              label="Occurrence Type"
              class="mt-0 pt-0 px-3"
              v-model="formData.is_repetitive"
              :error-messages="isRepetitiveErrors"
              @blur="$v.formData.is_repetitive.$touch()"
            >
              <v-radio label="One time" :value="false" />
              <v-radio label="Repetitive" :value="true" />
            </v-radio-group>
          </div>
          <div class="w-50">
            <v-text-field
              v-model="formData.occur_days"
              label="Occurrence days"
              type="number"
              @change="() => validateMinValue('occur_days', 0, 'formData')"
              class="pt-3 pl-3"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="occurDaysErrors"
              @blur="$v.formData.occur_days.$touch()"
            />
          </div>
        </div>

        <SKUSDataTable />
        <!--begin::Actions-->
        <div class="mb-4 mt-6 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SKUSDataTable from "@/own/components/orderManagement/subscriptionManagement/SKUSDataTable";
import {
  EDIT_RULES,
  SET_RULES,
  SET_RULES_SKUS,
} from "@/core/services/store/subscriptionManagement.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddEditRulesModal",
  mixins: [validationMixin, fieldValueValidation],
  components: { SKUSDataTable },
  validations: {
    formData: {
      occur_days: { required },
      is_repetitive: { required },
    },
  },
  data: () => ({
    dialog: false,
    lastElementIndex: null,
    formData: {
      id: null,
      occur_days: null,
      is_repetitive: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      const rule = {
        ...this.formData,
        skus: this.$store.getters.getSUBSCRIPTIONSRulesSKUS,
      };
      if (this.lastElementIndex !== null) {
        let data = {
          newItem: rule,
          lastElementIndex: this.lastElementIndex,
        };
        this.$store.commit(EDIT_RULES, data);
      } else this.$store.commit(SET_RULES, rule);

      this.toggleModal();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        occur_days: null,
        is_repetitive: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetFormData();
      this.dialog = !this.dialog;
    },
    initData(index, newItem) {
      this.lastElementIndex = index;
      this.formData.id = newItem.id;
      this.formData.occur_days = newItem.occur_days;
      this.formData.is_repetitive = newItem.is_repetitive;
      this.$store.commit(SET_RULES_SKUS, newItem.skus);
      this.dialog = true;
    },
  },
  computed: {
    headerText: function () {
      if (this.lastElementIndex !== null) return "Edit Item";
      else return "Add New Item";
    },
    submitButtonText: function () {
      if (this.lastElementIndex !== null) return "Update";
      else return "Submit";
    },
    occurDaysErrors: function () {
      return this.handleFormValidation("occur_days");
    },
    isRepetitiveErrors: function () {
      return this.handleFormValidation("is_repetitive");
    },
  },
};
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rules"
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="300"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >Rules</span
          >
          <button
            type="submit"
            class="btn btn-light-info"
            @click="showAddModal"
          >
            New Item
          </button>
        </div>
      </template>

      <template v-slot:item.is_repetitive="{ value }">
        {{ value === true ? "Repetitive" : "One time" }}
      </template>

      <template v-slot:item.skus="{ value }">
        <button
          class="btn btn-light-info py-1 poppins"
          @click="() => toggleViewSKUSDialog(value)"
        >
          View SKUS
        </button>
        <ViewSKUSModal ref="skus" />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <AddEditRulesModal ref="rules" />
  </div>
</template>

<script>
import AddEditRulesModal from "@/own/components/orderManagement/subscriptionManagement/AddEditRulesModal";
import ViewSKUSModal from "@/own/components/orderManagement/subscriptionManagement/ViewSKUSModal";
import {
  REMOVE_RULES,
  SET_RULES_SKUS,
} from "@/core/services/store/subscriptionManagement.module";
export default {
  name: "RulesDatatable",
  components: { ViewSKUSModal, AddEditRulesModal },
  computed: {
    rules: function () {
      return this.$store.getters.getSUBSCRIPTIONSRules;
    },
    headers: function () {
      return [
        {
          text: "Occurrence days",
          value: "occur_days",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Type of occurrence",
          value: "is_repetitive",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "SKUS",
          value: "skus",
          align: "left",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
  },
  methods: {
    showEditModal(item) {
      const lastElementIndex = this.rules.findIndex(
        (elem) => elem.id === item.id
      );
      this.$refs.rules.initData(lastElementIndex, item);
    },
    showAddModal() {
      this.$refs.rules.initData(null, {
        id: null,
        occur_days: null,
        is_repetitive: null,
        skus: [],
      });
    },
    remove(item) {
      this.$store.commit(REMOVE_RULES, item);
    },
    toggleViewSKUSDialog(item) {
      this.$refs.skus.toggleDialog();
      this.$store.commit(SET_RULES_SKUS, item);
    },
  },
};
</script>

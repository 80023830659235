<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-600px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="min-h-75px" v-if="!$store.getters.isClient">
          <v-autocomplete
            v-model="formData.client_id"
            dense
            label="Client"
            item-text="text"
            item-value="index"
            clearable
            outlined
            :items="clients"
            hide-no-data
            hide-selected
            :error-messages="clientIdErrors"
            @blur="$v.formData.client_id.$touch()"
          />
        </div>

        <div class="min-h-75px">
          <v-autocomplete
            v-model="formData.sku_id"
            :items="skus"
            item-text="text"
            item-value="index"
            label="SKUS"
            dense
            outlined
            clearable
            :error-messages="skuIdErrors"
            @blur="$v.formData.sku_id.$touch()"
          />
        </div>

        <div class="min-h-75px">
          <v-text-field
            v-model="formData.title"
            label="Title"
            dense
            outlined
            clearable
            :error-messages="titleErrors"
            @blur="$v.formData.title.$touch()"
          />
        </div>

        <div class="d-flex flex-column flex-md-row align-center">
          <div class="w-50">
            <v-radio-group
              row
              mandatory
              label="Status"
              class="mt-0 pt-0 px-3"
              v-model="formData.status"
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            >
              <v-radio label="Enabled" :value="true" />
              <v-radio label="Disabled" :value="false" />
            </v-radio-group>
          </div>
          <div class="w-50">
            <v-text-field
              v-model="formData.duration"
              label="Duration in days"
              type="number"
              @change="() => validateMinValue('duration', 0, 'formData')"
              class="pt-3 pl-3"
              dense
              outlined
              clearable
              :error-messages="durationErrors"
              @blur="$v.formData.duration.$touch()"
            />
          </div>
        </div>

        <div class="mt-4 pa-0 w-100">
          <RulesDatatable />
        </div>
        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import RulesDatatable from "@/own/components/orderManagement/subscriptionManagement/RulesDatatable";
import {
  SET_CLIENTS,
  SET_RULES,
  SET_SELECTED_CLIENT,
} from "@/core/services/store/subscriptionManagement.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  components: { RulesDatatable },
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      client_id: { required },
      sku_id: { required },
      title: { required },
      duration: { required },
      status: { required },
      rules: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverSKUS: [],
    entries: [],
    formData: {
      id: null,
      client_id: null,
      sku_id: null,
      title: null,
      duration: 30,
      status: true,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/order_management/subscriptions/edit", sendData)
          .then(({ data }) => {
            this.serverSKUS = data.skus;
            this.entries = data.skus;
            this.formData = data.subscription;
            this.$store.commit(SET_RULES, data.subscription.rules);
            this.$store.commit(SET_CLIENTS, data.clients);
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        this.$store.commit(SET_RULES, []);
        ApiService.post("/order_management/subscriptions/create")
          .then(({ data }) => {
            this.$store.commit(SET_CLIENTS, data.clients);
            this.serverSKUS = data.skus;
            this.entries = data.skus;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$store.getters.isClient) delete this.formData.client_id;
      let sendData = {
        ...this.formData,
        rules: this.$store.getters.getSUBSCRIPTIONSRules,
      };

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      this.pageLoader(true);
      ApiService.post(`/order_management/subscriptions/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    getData() {
      if (this.formData.client_id) {
        this.entries = this.serverSKUS.filter(
          (item) => this.formData.client_id === item.client_id
        );
      } else this.entries = [...this.serverSKUS];
    },
    resetFormData() {
      this.entries = [];
      this.formData = {
        client_id: null,
        sku_id: null,
        title: null,
        duration: 30,
        status: true,
      };
      this.$store.commit(SET_SELECTED_CLIENT, null);
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    clients: function () {
      return this.$store.getters.getSUBSCRIPTIONSClients;
    },
    skus: function () {
      return this.entries;
    },
    clientIdErrors: function () {
      return this.handleFormValidation("client_id");
    },
    skuIdErrors: function () {
      return this.handleFormValidation("sku_id");
    },
    titleErrors: function () {
      return this.handleFormValidation("title");
    },
    durationErrors: function () {
      return this.handleFormValidation("duration");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
  },
  watch: {
    formData: {
      handler(newValue) {
        if (newValue.client_id)
          this.$store.commit(SET_SELECTED_CLIENT, newValue.client_id);
        else this.$store.commit(SET_SELECTED_CLIENT, null);
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style>
.v-radio label {
  margin-bottom: 0;
}
</style>
